import { navigate } from '@reach/router'
import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'

import { SectionNode } from '../pages/index'

import facebook from '../images/facebook.png'
import twitter from '../images/twitter.png'
import linkedin from '../images/linkedin.png'

import styles from './nav.module.scss'

interface Props {
  sections: SectionNode[]
}
const Nav = ({ sections }: Props) => {
  const navigateTo = (sectionId: number) => {
    navigate(`#part-${sectionId}`)
  }

  return (
    <Menu styles={burgerStyles}>
      {sections.map((section: SectionNode, i: number) => (
        <a
          className={styles.burgerLink}
          key={i}
          href="#"
          onClick={e => {
            e.preventDefault()
            navigateTo(i + 1)
          }}
        >
          {section.title}
        </a>
      ))}
      <div className={styles.socialMediaLinks}>
        <FacebookShareButton url="https://chronicles.northvolt.com/">
          <img src={facebook} />
        </FacebookShareButton>
        <LinkedinShareButton url="https://chronicles.northvolt.com/">
          <img src={linkedin} />
        </LinkedinShareButton>
        <TwitterShareButton url="https://chronicles.northvolt.com">
          <img src={twitter} />
        </TwitterShareButton>
      </div>
    </Menu>
  )
}

export default Nav

const burgerStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '30px',
    top: '30px',
  },
  bmBurgerBars: {
    background: '#fff',
    boxShadow: '1px 1px 2px rgba(0,0,0,.1)',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
}
