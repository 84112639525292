import React, { Component, createRef } from 'react'
import LazyLoad from 'react-lazyload'
import { Waypoint } from 'react-waypoint'

import { Muted } from './controls'

import styles from './video.module.scss'

interface Props {
  video: string
  poster: string
  withAudio: boolean
  iterator: number
  introSlide?: boolean
  loop: boolean
  text: any
  textOffset: number
}

interface State {
  muted: boolean
  isPlaying: boolean
  currentTime: number
  duration: number
  offsetWidth: number
  offsetHeight: number
  videoWidth: string
  videoHeight: string
}

class Video extends Component<Props, State> {
  state: State = {
    muted: true,
    isPlaying: false,
    currentTime: 0,
    duration: 0,
    offsetHeight: 1334,
    offsetWidth: 750,
    videoWidth: 'auto',
    videoHeight: 'auto',
  }

  videoRef = createRef<HTMLVideoElement>()

  componentDidMount = () => {
    this.setVideoSize()
  }

  onEnterBefore = () => {
    if (this.videoRef.current) {
      this.setState({
        offsetHeight: this.videoRef.current.height,
        offsetWidth: this.videoRef.current.width,
      })
      this.setVideoSize()
    }
  }

  // nothing to see here, please move along...
  setVideoSize = () => {
    const { introSlide, withAudio } = this.props

    const videoResolution = {
      x: this.videoRef.current ? this.videoRef.current.offsetWidth : 750,
      y: this.videoRef.current ? this.videoRef.current.offsetHeight : 1334,
    }

    let videoHeight = 'auto'
    let videoWidth = 'auto'

    if (
      introSlide ||
      window.innerWidth / window.innerHeight >
        videoResolution.x / videoResolution.y
    ) {
      // videoHeight = videoResolution.x / videoResolution.y >= 1 ? 'auto' : '100vh'
      videoHeight = '100vh'
      videoWidth =
        videoResolution.x / videoResolution.y >= 1 || introSlide
          ? '100%'
          : 'auto'
    } else {
      videoHeight = withAudio ? 'auto' : '100vh'
    }
    // quick and dirty...
    if (this.props.iterator === 1) {
      videoHeight = '100vh'
    }

    this.setState({
      videoHeight,
      videoWidth,
    })
  }

  onEnter = () => {
    const { iterator, video } = this.props

    if (this.videoRef.current) {
      const src = this.videoRef.current.getAttribute('src')
      if (!src) {
        this.videoRef.current.setAttribute('src', video)
        this.videoRef.current.load()
      }
      if (!this.state.muted) {
        this.videoRef.current.muted = false
      }
      this.videoRef.current.play()
    }

    // preload previous and next video
    const preloadVideos = [
      document.getElementById(`video-${iterator - 1}`), // previous
      document.getElementById(`video-${iterator + 1}`), // next
    ]

    for (const v of preloadVideos) {
      if (v && v instanceof HTMLVideoElement) {
        const src = v.getAttribute('src')
        const dataSrc = v.getAttribute('data-src')
        if (!src) {
          v.setAttribute('src', dataSrc || '')
          v.load()
        }
      }
    }
  }

  onLeave = (props: any) => {
    const { iterator } = this.props

    // mute
    if (this.videoRef.current) {
      this.videoRef.current.muted = true
      this.videoRef.current.pause()
    }

    // remove slide before or after depending on scroll direction
    if (props.currentPosition === 'above') {
      const prevVideo = document.getElementById(`video-${iterator - 1}`)
      if (prevVideo && prevVideo instanceof HTMLVideoElement) {
        this.destroyVideo(prevVideo)
      }
    } else if (props.currentPosition === 'below') {
      const nextVideo = document.getElementById(`video-${iterator + 1}`)
      if (nextVideo && nextVideo instanceof HTMLVideoElement) {
        this.destroyVideo(nextVideo)
      }
    }
  }

  destroyVideo = (videoEle: HTMLVideoElement) => {
    videoEle.pause()
    videoEle.removeAttribute('src')
    videoEle.load()
  }

  play = () => {
    if (this.videoRef.current) {
      this.videoRef.current.play()
    }
  }

  pause = () => {
    if (this.videoRef.current) {
      this.videoRef.current.pause()
    }
  }

  slide = () => {
    return
  }

  toggleMute = () => {
    if (this.videoRef.current) {
      this.videoRef.current.muted = !this.state.muted
      this.setState({
        muted: !this.state.muted,
      })
    }
  }

  render = () => {
    const {
      iterator,
      poster,
      video,
      withAudio,
      introSlide,
      loop,
      text,
      textOffset,
    } = this.props
    const { videoHeight, videoWidth } = this.state

    const videoAttributes = {
      ref: this.videoRef,
      playsInline: true,
      controls: withAudio, // TODO: Replace with custom controls
      poster,
      muted: this.state.muted,
      ...(loop && { loop: true }),
    }

    return (
      <LazyLoad height="100vh" offset={2000} once>
        <Waypoint
          onEnter={this.onEnter}
          onLeave={this.onLeave}
          topOffset="-60%"
          bottomOffset="40%"
        />
        <Waypoint
          onEnter={this.onEnterBefore}
          topOffset="60%"
          bottomOffset="-40%"
        />
        <div className={styles.videoWrapper}>
          {withAudio && this.state.muted && (
            <Muted toggleMute={this.toggleMute} />
          )}
          {withAudio && this.props.text && (
            <div
              className={styles.withAudioText}
              style={{ left: `${textOffset}px` }}
            >
              <div
                className={styles.slideText}
                dangerouslySetInnerHTML={{
                  __html: text.replace(/(\u00a0|0xFFFD|�)/g, ' '),
                }}
              />
            </div>
          )}
          {/* {withAudio && !this.state.muted && (
            <>
              <UnMuted toggleMute={this.toggleMute} />
              <Controls
                isPlaying={this.state.isPlaying}
                play={this.play}
                pause={this.pause}
                slide={this.slide}
                currentTime={this.state.currentTime}
                duration={this.state.duration}
              />
            </>
          )} */}
          <div>
            <video
              {...videoAttributes}
              id={`video-${iterator}`}
              data-src={video}
              className={introSlide ? styles.introSlide : ''}
              style={{ height: videoHeight, width: videoWidth }}
            >
              {/* <source src={video} type="video/mp4" /> */}
            </video>
          </div>
        </div>
      </LazyLoad>
    )
  }
}

export default Video
