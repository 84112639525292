import React from 'react'
import { Direction, PlayerIcon, Slider } from 'react-player-controls'

import styles from './video.module.scss'
import slideStyles from '../slide.module.scss'

interface ToggleMuteProps {
  toggleMute: () => void
}

interface SliderProps {
  isPlaying: boolean
  play: () => void
  pause: () => void
  slide: () => void
  currentTime: number
  duration: number
}

interface BarProps {
  style: any
}

export const Muted = ({ toggleMute }: ToggleMuteProps) => (
  <div className={styles.mutedWrapper}>
    <PlayerIcon.SoundOn width={50} fill="white" onClick={toggleMute} />
    <label>Unmute</label>
  </div>
)

export const UnMuted = ({ toggleMute }: ToggleMuteProps) => (
  <div className={styles.mutedWrapper}>
    <PlayerIcon.SoundOff width={50} fill="white" onClick={toggleMute} />
  </div>
)

export const Controls = ({
  currentTime,
  duration,
  isPlaying,
  play,
  pause,
  slide,
}: SliderProps) => {
  const SliderBar = ({ style }: BarProps) => <div style={{ ...style }} />
  const SliderHandle = ({ style }: BarProps) => <div style={{ ...style }} />

  return (
    <div className={styles.sliderWrapper}>
      {isPlaying ? (
        <PlayerIcon.Pause width={8} fill="white" onClick={pause} />
      ) : (
        <PlayerIcon.Play width={8} fill="white" onClick={play} />
      )}
      <Slider
        isEnabled
        direction={Direction.HORIZONTAL}
        onChange={(newValue: any) => global.console.log(newValue)}
        onChangeStart={(startValue: any) => global.console.log(startValue)}
        onChangeEnd={(endValue: any) => global.console.log(endValue)}
        onIntent={(intent: any) => global.console.log(intent)}
        onIntentStart={(intent: any) => global.console.log(intent)}
        onIntentEnd={() => global.console.log('intent end')}
        style={{
          position: 'absolute',
          zIndex: '13',
          height: '10px',
          width: '90%',
          left: '20px',
          borderRadius: '4px',
          background: 'rgb(238, 238, 238)',
          transition: 'width 0.1s ease 0s',
          cursor: 'pointer',
        }}
      >
        <SliderBar
          style={{
            position: 'absolute',
            background: '#72d687',
            borderRadius: '4px',
            top: '0',
            bottom: '0',
            left: '0',
            width: '22.25%',
          }}
        />
        <SliderBar
          style={{
            position: 'absolute',
            background: 'rgba(0, 0, 0, 0.05)',
            borderRadius: '4px',
            top: '0',
            bottom: '0',
            left: '0',
            width: '22.25%',
          }}
        />
        <SliderHandle
          style={{
            position: 'absolute',
            background: '#72d687',
            width: '16px',
            height: '16px',
            borderRadius: '100%',
            transform: 'scale(1)',
            transition: 'transform 0.2s ease 0s',
            top: '0',
            left: '22.25%',
            marginTop: '-4px',
            marginLeft: '-8px',
          }}
        />
      </Slider>
    </div>
  )
}
