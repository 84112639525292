import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Nav from '../components/nav'
import Section from '../components/section'

import { MediaFile, SiteMeta, Text } from '../globals'

export interface SectionNode {
  title: string
  video?: MediaFile
  poster?: MediaFile
  text?: Text
  slides: SlideNode[]
}

export interface SlideNode {
  title: string
  type: string
  media: MediaFile
  text?: Text
  poster: MediaFile
  loop: boolean
}

export interface PageNode {
  title: string
  sections: SectionNode[]
}

interface PageProps {
  data: {
    site: SiteMeta
    contentfulPage: PageNode
  }
}

export const storiesPageQuery = graphql`
  query PageQuery {
    contentfulPage(contentful_id: { eq: "nv-chronicals-page-1" }) {
      sections {
        title
        video {
          title
          file {
            url
          }
        }
        poster {
          title
          file {
            url
          }
        }
        text {
          childMarkdownRemark {
            html
          }
        }
        slides {
          type
          media {
            title
            file {
              url
            }
          }
          text {
            childMarkdownRemark {
              html
            }
          }
          poster {
            title
            file {
              url
            }
          }
          loop
        }
      }
    }
  }
`

class IndexPage extends Component<PageProps> {
  render() {
    const { contentfulPage } = this.props.data

    return (
      <Layout>
        <Nav sections={contentfulPage.sections} />
        {contentfulPage.sections.map((section: SectionNode, i: number) => (
          <Section key={i} section={section} sectionNo={i + 1} />
        ))}
      </Layout>
    )
  }
}
export default IndexPage
