import React from 'react'

import styles from './section.module.scss'

import { SectionNode, SlideNode } from '../pages/index'
import Slide, { SlideType } from './slide'

let iterator = 0

interface Props {
  section: SectionNode
  sectionNo: number
}
const Section = ({ section, sectionNo }: Props) => {
  return (
    <section className={styles.section}>
      {section.video && section.poster && (
        <Slide
          key={sectionNo}
          slide={{
            title: section.title,
            media: section.video,
            poster: section.poster,
            text: section.text,
            type: SlideType.BACKGROUND_VIDEO,
            loop: true,
          }}
          slideId={`part-${sectionNo}`}
          nextId={`slide-${sectionNo}-1`}
          iterator={iterator++}
          introSlide
        />
      )}
      {section.slides &&
        section.slides.map((slide: SlideNode, i: number) => {
          return (
            <Slide
              key={`${sectionNo}-${i}`}
              slide={slide}
              slideId={`slide-${sectionNo}-${i + 1}`}
              nextId={
                section.slides.length === i + 1
                  ? `part-${sectionNo + 2}`
                  : `slide-${sectionNo + 1}-${i + 2}`
              }
              iterator={slide.type !== SlideType.IMAGE ? iterator++ : iterator}
            />
          )
        })}
    </section>
  )
}

export default Section
